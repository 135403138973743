<template>
  <div class="order-create__section">

    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_PersonalInformation'])"></div>
      <TitleHorizontal
          :value="$t('fbm_SendFromInformation.localization_value.value')"
          :rightBtn="!show"
          :rightBtnType="'edit'"
          :rightBtnText="$t('common_edit.localization_value.value')"
          @rightBtnClick="$emit('changeStep', 1)"
      />
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--                <div class="admin-edit" @click="editTranslate(['fbm_ContactName'])"></div>-->
                {{$t('common_name.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.sendFrom"
                    :deep="'first_name'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_PhoneNumber'])"></div>
                {{$t('common_surName.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.sendFrom"
                    :deep="'last_name'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_PhoneNumber'])"></div>
                {{$t('profile_companyName.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.sendFrom"
                    :deep="'company_name'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_PersonalInformation'])"></div>
      <TitleHorizontal
              :value="$t('fbm_PersonalInformation.localization_value.value')"
              :rightBtn="!show"
              :rightBtnType="'edit'"
              :rightBtnText="$t('common_edit.localization_value.value')"
              @rightBtnClick="$emit('changeStep', 1)"
      />
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50 mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ContactName'])"></div>
                {{$t('fbm_ContactName.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{personal.information.personalName}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_PhoneNumber'])"></div>
                {{$t('fbm_PhoneNumber.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{personal.information.personalPhone}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_PhoneNumber'])"></div>
                {{$t('fbm_CompanyName.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="personal.information.personalCompany"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_PhoneNumber'])"></div>
                {{$t('fbm_Email.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="personal.information.personalEmail"
                />
              </div>
            </div>

          </div>
          <div class="block-table__row">
            <template v-if="FBM.data.transactionAddress === FBM.data.transactionAddressTypes.none ||
								FBM.data.transactionAddress === FBM.data.transactionAddressTypes.paypal">
              <div class="block-table__col block-table__col--20 block-table__col--sm-50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
                  {{$t('fbm_Address.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{deliveryPayPal.delivery.address}}
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_addressSecond'])"></div>
                  {{$t('common_addressSecond.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  <ValueHelper
                      :value="deliveryPayPal.delivery.address2"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_City'])"></div>
                  {{$t('fbm_City.localization_value.value')}}
                </div>
                <div class="block-table__content">
                  {{deliveryPayPal.delivery.city}}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50 mb-3">
                <div class="block-table__label "
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_Address'])"></div>
                  {{$t('fbm_Address.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{deliveryEtsy.delivery.address}}
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_addressSecond'])"></div>
                  {{$t('common_addressSecond.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{deliveryEtsy.delivery.address2}}
                </div>
              </div>
              <div class="block-table__col block-table__col--40 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_City'])"></div>
                  {{$t('fbm_City.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{deliveryEtsy.delivery.city}}
                </div>
              </div>
            </template>

            <template v-if="FBM.data.transactionAddress === FBM.data.transactionAddressTypes.none ||
								FBM.data.transactionAddress === FBM.data.transactionAddressTypes.paypal">
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_Region'])"></div>
                  {{$t('fbm_Region.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{deliveryPayPal.delivery.region}}
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_ZipCode'])"></div>
                  {{$t('fbm_ZipCode.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{deliveryPayPal.delivery.zip}}
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_Country', 'express_ViewCustomLimits'])"></div>
                  {{$t('fbm_Country.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{deliveryPayPal.delivery.country.label}}
                </div>
              </div>
            </template>

            <template v-else>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_Region'])"></div>
                  {{$t('fbm_Region.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{deliveryEtsy.delivery.region}}
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_ZipCode'])"></div>
                  {{$t('fbm_ZipCode.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{deliveryEtsy.delivery.zip}}
                </div>
              </div>
              <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['fbm_Country', 'express_ViewCustomLimits'])"></div>
                  {{$t('fbm_Country.localization_value.value')}}
                </div>
                <div class="block-table__content word-break">
                  {{deliveryEtsy.delivery.country.label}}
                </div>
              </div>
            </template>

            <div class="block-table__col block-table__col--40 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_CommentOptional', 'fbm_PleaseVaseInside'])"></div>
                {{$t('fbm_CommentOptional.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.comment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <template v-if="!isWarehouse && FBM.data.ProformProducts.length > 0 && parseFloat(FBM.data.ProformProducts[0].product.price) > 0">
      <div class="order-create__title-horizontal"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_products'])"></div>
        <TitleHorizontal
                :value="$t('fbm_products.localization_value.value')"
                :rightBtn="!show"
                :rightBtnType="'edit'"
                :rightBtnText="$t('common_edit.localization_value.value')"
                @rightBtnClick="$emit('changeStep', 2)"
        />
      </div>


      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col">


          <div class="block-table__name mb-4" v-if="!isMobileFunc()"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_ProductConsolidation'])"></div>
            {{$t('fbm_ProductConsolidation.localization_value.value')}}
          </div>

          <div class="custom-row">
            <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                 v-for="(item, index) in FBM.data.ProformProducts"
                 :key="index"
            >
              <div class="confirm-product-card">
                <div class="confirm-product-card__items">
                  <div class="confirm-product-card__item" v-if="item.product.proformData.proformCategory !== ''">
                    <div class="confirm-product-card__item-name"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate([
                          'proform_category',
                        ])"></div>
                      {{$t('proform_category.localization_value.value')}}
                    </div>
                    <div class="confirm-product-card__item-val">
<!--                      {{item.product.proformData.proformCategory.translationStorage.en.name}}-->
                      <ValueHelper
                          :value="item.product.proformData.proformCategory"
                          :deep="'translationStorage.en.name'"
                      />
                    </div>
                  </div>

                  <template v-if="localProformTypes = item.product.proformData.proformTypes">
                    <template v-for="(itemType, index) in $store.getters.getProformProductInitialize.proformTypes">
                      <div class="confirm-product-card__item" :key="index" v-if="!isUnitedKingdomCountry">
                        <div class="confirm-product-card__item-name">
                          {{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_Ua'].name }}
                        </div>
                        <div class="confirm-product-card__item-val">
                          {{localProformTypes[itemType.id].typeValue !== '' ?
                          localProformTypes[itemType.id].typeValue.value.translationStorage.ua.name : ''}}
                        </div>
                      </div>
                      <div class="confirm-product-card__item" :key="index + '2'">
                        <div class="confirm-product-card__item-name">
                          {{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name }}
                        </div>
                        <div class="confirm-product-card__item-val">
                          {{localProformTypes[itemType.id].typeValue !== '' ?
                          localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : ''}}
                        </div>
                      </div>
                    </template>
                  </template>
                </div>

                <div class="confirm-product-card__item"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
							'express_Price',
						])"></div>
                  <div class="confirm-product-card__item-name">
                    {{$t('express_Price.localization_value.value')}}
                  </div>
                  <div class="confirm-product-card__item-val">
                    {{item.product.price}}
                  </div>
                </div>

                <div class="confirm-product-card__item"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
							'express_Quantity',
						])"></div>
                  <div class="confirm-product-card__item-name">
                    {{$t('express_Quantity.localization_value.value')}}
                  </div>
                  <div class="confirm-product-card__item-val">
                    {{item.product.itemsQuantity}}
                  </div>
                </div>

                <div class="confirm-product-card__bottom" v-if="item.product.handMade"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                          'product_Handmade',
                        ])"></div>
                  <StatusIcoBtn :type="'handmade'" class="mr-2"/>
                  {{$t('product_Handmade.localization_value.value')}}
                </div>
              </div>
            </div>
          </div>
          <div class="block-table__total site-line-total mt-0 mb-4">
            <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
							'common_TotallyPerOrder',
						])"></div>
              {{$t('common_TotallyPerOrder.localization_value.value')}}
            </div>

            <span>${{calculatePrice}}</span>
          </div>

        </div>
      </div>
    </template>

    <div class="custom-row" v-if="!isLabel && FBM.data.productsForCellArray.length > 0 && _.has(_.first(FBM.data.productsForCellArray).data.Product.product.productObject, 'id')">
      <div class="custom-col">
        <div class="d-flex align-center" style="height: 42px;">
          <RadioDefault
              class="white-space-line width-initial mr-3"
              :label="FBM.data.Order.data.warehouse.name"
              :name="'warehouse'"
              :value="true"
              :disabled="true"
          />
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row"
         v-if="FBM.data.productsForCellArray.length > 0 && _.has(_.first(FBM.data.productsForCellArray).data.Product.product.productObject, 'id')"
    >
      <div class="order-create__col custom-col">

        <div class="block-table__name block-table__name--mobile  mb-4"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_ProductWarehouse'])"></div>
          {{$t('fbm_ProductWarehouse.localization_value.value')}}
        </div>

        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
        >
          <table class="site-table">
            <thead>
            <tr>
              <th>SKU</th>
              <th v-if="!checkSelectedUSACountry">{{$t('fbm_ProductDescription.localization_value.value')}}</th>
              <th class="text-lg-right" v-if="!checkSelectedUSACountry">{{$t('fbm_ProductPrice.localization_value.value')}}</th>
              <th class="text-lg-right">{{$t('fbm_ItemsQty.localization_value.value')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr
                    v-for="(item, index) in FBM.data.productsForCellArray"
                    :key="index"
            >
              <td>
                {{item.data.Product.product.productObject.name_for_fbm}}
              </td>
              <td v-if="!checkSelectedUSACountry">{{item.data.Product.product.description}}</td>
              <td align="right" v-if="!checkSelectedUSACountry">{{item.data.Product.product.price}}</td>
              <td align="right">{{item.data.Product.product.count}}</td>
            </tr>
            </tbody>
          </table>

        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100">
              <div class="site-table-mobile__item"
                   v-for="(item, index) in FBM.data.productsForCellArray"
                   :key="index"
              >
                <div class="site-table-mobile__head">
                  {{item.data.Product.product.productObject.name_for_fbm}}
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col custom-col--33">
                    Q-ty
                    <span>
											{{item.data.Product.product.count}}
										</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_Dimentions'])"></div>
            {{$t('fbm_Dimentions.localization_value.value')}}
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_DimentionsWeightKG'])"></div>
                {{$t('fbm_DimentionsWeightKG.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.Dimensions.data.weightLB"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_DimentionsWeightGR'])"></div>
                {{$t('fbm_DimentionsWeightGR.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.Dimensions.data.weightOZ"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_DimentionsHeight'])"></div>
                {{$t('fbm_DimentionsHeight.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.Dimensions.data.height"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_DimentionsWidth'])"></div>
                {{$t('fbm_DimentionsWidth.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.Dimensions.data.width"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_DimentionsLength'])"></div>
                {{$t('fbm_DimentionsLength.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.Dimensions.data.dimensionLength"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row" v-if="isWarehouse && !isWarehouseCambridge && FBM.data.isEngraving">
      <div class="order-create__col custom-col"
           v-for="(item, indexEngraving) in FBM.data.Engravings"
           :key="indexEngraving"
      >
        <div class="order-create__table block-table">
          <div class="block-table__name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_EngravingFile'])"></div>
            {{$t('fbm_EngravingFile.localization_value.value')}}
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingLength'])"></div>
                {{$t('fbm_engravingLength.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="item.data.Dimensions.data.dimensionLength"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingWidth'])"></div>
                {{$t('fbm_engravingWidth.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="item.data.Dimensions.data.width"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingCount'])"></div>
                {{$t('fbm_engravingCount.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="item.data.count"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="item.data.customization"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product'])"></div>
                {{$t('calculatorEngraving_product.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="item.data.customization.label"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingPrice'])"></div>
                {{$t('fbm_engravingPrice.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="item.data.engravingPrice"
                />
              </div>
            </div>
          </div>
        </div>



        <div class="block-table mt-2"
             v-if="item.data.EngravingFile.data.files.length > 0"
        >
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50 block-table__col--sm-50">
              <div v-for="(itemFile, index) in item.data.EngravingFile.data.files" :key="index" style="display: flex; align-items: center; margin-bottom: 5px;">
                <img width="25px" src="/img/finance-group/list-no-image.svg" alt="img" style="margin-right: 8px;">
                {{itemFile.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="block-table mt-4"
             v-if="Object.keys(item.data.EngravingFile.data.downloadFiles).length > 0"
        >
          <div class="block-table__row product-img-row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <DownloadFileBlock
                  v-for="(itemFile, index) in item.data.EngravingFile.data.downloadFiles"
                  :key="index+'file'"
                  :file="itemFile"
                  :optionDelete="false"
              />
              <TableHeadButton
                  v-if="FBM.data.isEngraving && show"
                  class="ml-0"
                  :value="$t('fbm_EngravingFileName.localization_value.value')"
                  :ico="'import'"
                  @click.native="downloadEngravingFile(item.data.EngravingFile, 'dxf', 'Engraving')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row" v-if="isWarehouse && !isWarehouseCambridge && FBM.data.UVPrinting.data.isUVPrinting">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['calculatorEngraving_UVPrinting'])"></div>
            {{$t('calculatorEngraving_UVPrinting.localization_value.value')}}
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingLength'])"></div>
                {{$t('packaging_Length.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.UVPrinting.data.Dimensions.data.dimensionLength"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingWidth'])"></div>
                {{$t('packaging_Width.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.UVPrinting.data.Dimensions.data.width"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingCount'])"></div>
                {{$t('fbm_engravingCount.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.UVPrinting.data.count"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="FBM.data.UVPrinting.data.customization"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product'])"></div>
                {{$t('calculatorEngraving_product.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.UVPrinting.data.customization.label"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingPrice'])"></div>
                {{$t('fbm_engravingPrice.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.UVPrinting.data.resultCost"
                />
              </div>
            </div>
          </div>
        </div>



        <div class="block-table mt-2"
             v-if="FBM.data.UVPrinting.data.File.data.files.length > 0"
        >
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50 block-table__col--sm-50">
              <div v-for="(item, index) in FBM.data.UVPrinting.data.File.data.files" :key="index" style="display: flex; align-items: center; margin-bottom: 5px;">
                <img width="25px" src="/img/finance-group/list-no-image.svg" alt="img" style="margin-right: 8px;">
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="block-table mt-4"
             v-if="Object.keys(FBM.data.UVPrinting.data.File.data.downloadFiles).length > 0"
        >
          <div class="block-table__row product-img-row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <DownloadFileBlock
                  v-for="(item, index) in FBM.data.UVPrinting.data.File.data.downloadFiles"
                  :key="index+'file'"
                  :file="item"
                  :optionDelete="false"
              />
              <TableHeadButton
                  v-if="FBM.data.UVPrinting.data.isUVPrinting && show"
                  class="ml-0"
                  :value="$t('calculatorEngraving_UVPrinting.localization_value.value')"
                  :ico="'import'"
                  @click.native="downloadEngravingFile(FBM.data.UVPrinting.data.File, 'dxf', 'UV printing')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row" v-if="isWarehouse && !isWarehouseCambridge && FBM.data.Embroidery.data.isEmbroidery">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_EmbroideryFile'])"></div>
            {{$t('fbm_EmbroideryFile.localization_value.value')}}
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_NumberOfStitches'])"></div>
                {{$t('calculatorEngraving_NumberOfStitches.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.Embroidery.data.stitches"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_quantity'])"></div>
                {{$t('calculatorEngraving_quantity.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.Embroidery.data.quantity"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="FBM.data.Embroidery.data.customization"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product'])"></div>
                {{$t('calculatorEngraving_product.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.Embroidery.data.customization.label"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingPrice'])"></div>
                {{$t('fbm_engravingPrice.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.Embroidery.data.resultCost"
                />
              </div>
            </div>
          </div>
        </div>



        <div class="block-table mt-2"
             v-if="FBM.data.Embroidery.data.File.data.files.length > 0"
        >
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50 block-table__col--sm-50">
              <div v-for="(item, index) in FBM.data.Embroidery.data.File.data.files" :key="index" style="display: flex; align-items: center; margin-bottom: 5px;">
                <img width="25px" src="/img/finance-group/list-no-image.svg" alt="img" style="margin-right: 8px;">
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="block-table mt-4"
             v-if="Object.keys(FBM.data.Embroidery.data.File.data.downloadFiles).length > 0"
        >
          <div class="block-table__row product-img-row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <DownloadFileBlock
                  v-for="(item, index) in FBM.data.Embroidery.data.File.data.downloadFiles"
                  :key="index+'file'"
                  :customTypeFile="'dst'"
                  :file="item"
                  :optionDelete="false"
              />
              <TableHeadButton
                  v-if="FBM.data.Embroidery.data.isEmbroidery && show"
                  class="ml-0"
                  :value="$t('calculatorEngraving_EmbroideryCalculator.localization_value.value')"
                  :ico="'import'"
                  @click.native="downloadEngravingFile(FBM.data.Embroidery.data.File, 'dst')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row" v-if="isWarehouse && !isWarehouseCambridge && FBM.data.TShirtPrinting.data.isTShirtPrinting">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_TShirtPrintingFile'])"></div>
            {{$t('fbm_TShirtPrintingFile.localization_value.value')}}
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_quantity'])"></div>
                {{$t('calculatorEngraving_quantity.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.TShirtPrinting.data.quantity"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="FBM.data.TShirtPrinting.data.customization"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_product'])"></div>
                {{$t('calculatorEngraving_product.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.TShirtPrinting.data.customization.label"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="FBM.data.TShirtPrinting.data.size"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_productSize'])"></div>
                {{$t('calculatorEngraving_productSize.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.TShirtPrinting.data.size"
                    :deep="'name'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingPrice'])"></div>
                {{$t('fbm_engravingPrice.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.TShirtPrinting.data.resultCost"
                />
              </div>
            </div>
          </div>
        </div>



        <div class="block-table mt-2"
             v-if="FBM.data.TShirtPrinting.data.File.data.files.length > 0"
        >
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50 block-table__col--sm-50">
              <div v-for="(item, index) in FBM.data.TShirtPrinting.data.File.data.files" :key="index" style="display: flex; align-items: center; margin-bottom: 5px;">
                <img width="25px" src="/img/finance-group/list-no-image.svg" alt="img" style="margin-right: 8px;">
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="block-table mt-4"
             v-if="Object.keys(FBM.data.TShirtPrinting.data.File.data.downloadFiles).length > 0"
        >
          <div class="block-table__row product-img-row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <DownloadFileBlock
                  v-for="(item, index) in FBM.data.TShirtPrinting.data.File.data.downloadFiles"
                  :key="index+'file'"
                  :file="item"
                  :optionDelete="false"
              />
              <TableHeadButton
                  v-if="FBM.data.TShirtPrinting.data.isTShirtPrinting && show"
                  class="ml-0"
                  :value="$t('calculatorEngraving_TShirtPrinting.localization_value.value')"
                  :ico="'import'"
                  @click.native="downloadEngravingFile(FBM.data.TShirtPrinting.data.File, 'dxf', 'T-shirt printing')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row" v-if="isWarehouse && !isWarehouseCambridge && FBM.data.LeatherStamp.data.isLeatherStamp">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['calculatorEngraving_LeatherStamp'])"></div>
            {{$t('calculatorEngraving_LeatherStamp.localization_value.value')}}
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_text'])"></div>
                {{$t('calculatorEngraving_text.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.LeatherStamp.data.stampingLine"
                />
              </div>
            </div>
          </div>
          <div class="block-table__col block-table__col--20 block-table__col--sm-50">
            <div class="block-table__label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fbm_engravingPrice'])"></div>
              {{$t('fbm_engravingPrice.localization_value.value')}}
            </div>
            <div class="block-table__content">
              <ValueHelper
                  :value="FBM.data.LeatherStamp.data.resultCost"
              />
            </div>
          </div>
        </div>



        <div class="block-table mt-2"
             v-if="FBM.data.LeatherStamp.data.File.data.files.length > 0"
        >
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50 block-table__col--sm-50">
              <div v-for="(item, index) in FBM.data.LeatherStamp.data.File.data.files" :key="index" style="display: flex; align-items: center; margin-bottom: 5px;">
                <img width="25px" src="/img/finance-group/list-no-image.svg" alt="img" style="margin-right: 8px;">
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="block-table mt-4"
             v-if="Object.keys(FBM.data.LeatherStamp.data.File.data.downloadFiles).length > 0"
        >
          <div class="block-table__row product-img-row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <DownloadFileBlock
                  v-for="(item, index) in FBM.data.LeatherStamp.data.File.data.downloadFiles"
                  :key="index+'file'"
                  :file="item"
                  :optionDelete="false"
              />
              <TableHeadButton
                  v-if="FBM.data.LeatherStamp.data.isLeatherStamp && show"
                  class="ml-0"
                  :value="$t('calculatorEngraving_LeatherStamp.localization_value.value')"
                  :ico="'import'"
                  @click.native="downloadEngravingFile(FBM.data.LeatherStamp.data.File, 'dxf', 'Leather stamp')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row" v-if="isWarehouse && !isWarehouseCambridge && FBM.data.PhotoPrint.data.isPhotoPrint">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['calculatorEngraving_PhotoPrint'])"></div>
            {{$t('calculatorEngraving_PhotoPrint.localization_value.value')}}
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_quantity'])"></div>
                {{$t('calculatorEngraving_quantity.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.PhotoPrint.data.quantity"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="FBM.data.PhotoPrint.data.size"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_productSize'])"></div>
                {{$t('calculatorEngraving_productSize.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.PhotoPrint.data.size.label"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50"
                 v-if="FBM.data.PhotoPrint.data.customization"
            >
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['calculatorEngraving_productSize'])"></div>
                {{$t('calculatorEngraving_product.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.PhotoPrint.data.customization.label"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_engravingPrice'])"></div>
                {{$t('fbm_engravingPrice.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.PhotoPrint.data.resultCost"
                />
              </div>
            </div>
          </div>
        </div>



        <div class="block-table mt-2"
             v-if="FBM.data.PhotoPrint.data.File.data.files.length > 0"
        >
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50 block-table__col--sm-50">
              <div v-for="(item, index) in FBM.data.PhotoPrint.data.File.data.files" :key="index" style="display: flex; align-items: center; margin-bottom: 5px;">
                <img width="25px" src="/img/finance-group/list-no-image.svg" alt="img" style="margin-right: 8px;">
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="block-table mt-4"
             v-if="Object.keys(FBM.data.PhotoPrint.data.File.data.downloadFiles).length > 0"
        >
          <div class="block-table__row product-img-row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <DownloadFileBlock
                  v-for="(item, index) in FBM.data.PhotoPrint.data.File.data.downloadFiles"
                  :key="index+'file'"
                  :file="item"
                  :customTypeFile="'psd'"
                  :optionDelete="false"
              />
              <TableHeadButton
                  v-if="FBM.data.PhotoPrint.data.isPhotoPrint && show"
                  class="ml-0"
                  :value="$t('calculatorEngraving_PhotoPrint.localization_value.value')"
                  :ico="'import'"
                  @click.native="downloadEngravingFile(FBM.data.PhotoPrint.data.File, 'psd', 'Photo print')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="order-create__row custom-row" v-if="show">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_status'])"></div>
            {{$t('fbm_status.localization_value.value')}}
          </div>
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_status'])"></div>
                {{$t('fbm_status.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{$t(`${FBM.data.Order.data.status.translation}.localization_value.value`)}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_WasSentAt'])"></div>
                {{$t('fbm_WasSentAt.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="FBM.data.receivedAt"
                        :date="true"
                        :dateType="'DD MMM, YYYY'"
                />
              </div>
            </div>
<!--            <div class="block-table__col block-table__col&#45;&#45;20"-->
<!--                 v-if="show">-->
<!--              <div class="block-table__label"-->
<!--                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['fbm_TrackingNumber'])"></div>-->
<!--                {{$t('fbm_TrackingNumber.localization_value.value')}}-->
<!--              </div>-->
<!--              <div class="block-table__content word-break">-->
<!--                <ValueHelper-->
<!--                        :value="FBM.data.Order.data.trackingNum"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->

            <div class="block-table__col block-table__col--20"
                 v-if="show && !isWarehouse">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_DHLTracknumber'])"></div>
                {{$t('fbm_DHLTracknumber.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.consolidation"
                    :deep="'user_tracking_number'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20"
                 v-if="show && !isWarehouse">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ConsoID'])"></div>
                {{$t('fbm_ConsoID.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="FBM.data.consolidation"
                    :deep="'id'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20 mt-3"
                 v-if="show && isConsolidation">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--                <div class="admin-edit" @click="editTranslate(['fbm_ConsoID'])"></div>-->
                System track
              </div>
              <div class="block-table__content">
                <a v-if="FBM.data.Order.data.orderOBJ && FBM.data.Order.data.orderOBJ.orderFbmInstance && FBM.data.Order.data.orderOBJ.orderFbmInstance.system_tracking_number"
                    :href="'https://expressposhta.com/search/' + FBM.data.Order.data.orderOBJ.orderFbmInstance.system_tracking_number" target="_blank" class="table-link btn-style">
                  <ValueHelper
                      :value="FBM.data.Order.data.orderOBJ"
                      :deep="'orderFbmInstance.system_tracking_number'"
                  />
                </a>
                <template v-else>—</template>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!--<div class="order-create__row custom-row">-->
    <!--<div class="order-create__col custom-col">-->
    <!--<div class="order-create__table block-table">-->
    <!--<div class="block-table__name"-->
    <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
    <!--<div class="admin-edit" @click="editTranslate(['fbm_Managering'])"></div>-->
    <!--{{$t('fbm_Managering.localization_value.value')}}-->
    <!--</div>-->
    <!--<div class="block-table__row">-->
    <!--<div class="block-table__col">-->
    <!--<div class="block-table__label"-->
    <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
    <!--<div class="admin-edit" @click="editTranslate(['fbm_LotNumber'])"></div>-->
    <!--{{$t('fbm_LotNumber.localization_value.value')}}-->
    <!--</div>-->
    <!--<div class="block-table__content">-->
    <!--{{FBM.data.lotNumber}}-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="order-create__section-label section-label"-->
    <!--v-if="FBM.data.Products.length > 0 && _.first(FBM.data.Products).product.id"-->
    <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
    <!--<div class="admin-edit" @click="editTranslate(['fba_products'])"></div>-->
    <!--{{ $t('fba_products.localization_value.value') }}-->
    <!--</div>-->

    <!--<div class="custom-row"-->
    <!--v-if="FBM.data.productsForCellArray.length > 0 && _.first(FBA.data.productsForCellArray).data.Product.product.id"-->
    <!--&gt;-->
    <!--<div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100"-->
    <!--v-for="(item, index) in FBA.data.productsForCellArray"-->
    <!--:key="index"-->
    <!--&gt;-->
    <!--<div class="confirm-product-card">-->
    <!--<div class="confirm-product-card__name">-->
    <!--<ValueHelper-->
    <!--:value="item.data.Product"-->
    <!--:deep="'product.productObject.name_for_fba'"-->
    <!--/>-->
    <!--</div>-->

    <!--<div class="confirm-product-card__items">-->
    <!--<div class="confirm-product-card__item">-->

    <!--<div class="confirm-product-card__item-name"-->
    <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
    <!--<div class="admin-edit" @click="editTranslate(['fbm_ItemsQty'])"></div>-->
    <!--{{$t('fbm_ItemsQty.localization_value.value')}}-->
    <!--</div>-->
    <!--<div class="confirm-product-card__item-val">-->
    <!--{{item.data.Product.product.count}}-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->


    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_ShippingPackage'])"></div>
      <TitleHorizontal
              :value="$t('fbm_ShippingPackage.localization_value.value')"
              :rightBtn="!show"
              :rightBtnType="'edit'"
              :rightBtnText="$t('common_edit.localization_value.value')"
              @rightBtnClick="$emit('changeStep', 3)"
      />
    </div>

    <div class="order-create__section-label section-label mb-3"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_ShippingPartner'])"></div>
      {{$t('fbm_ShippingPartner.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--20 d-flex"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_ChangeCarrier'])"></div>
        <DefaultCheckbox
            class="bold"
            :label="$t('fbm_ChangeCarrier.localization_value.value')"
            :value="FBM.data.changeCarrier"
            :disabled="true"
        />
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col d-flex">
        <RadioLabelPicture
                class="radio-off wfc mb-0"
                :label="FBM.data.shippingPartnerType.name"
                name="'addressType'"
                value="true"
                disabled="true"
                :arrow="true"
        >
          <template slot="ico">
            <img height="11" class="ml-1"
                 v-if="FBM.data.shippingPartnerType.logo_base64"
                 :src="FBM.data.shippingPartnerType.logo_base64" alt="ico">
          </template>
        </RadioLabelPicture>
        <span class="d-flex align-items-center">
					{{FBM.data.shippingPartner.name}}
				</span>
      </div>

      <div class="order-create__col custom-col d-flex flex-column"
           v-if="FBM.data.isUserLabel"
      >
        <div class="mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_userLabel'])"></div>
          <DefaultCheckbox
              class="bold big-label"
              :label="$t('fbm_userLabel.localization_value.value')"
              :value="FBM.data.isUserLabel"
              :disabled="true"
          />
        </div>

        <div class="block-table__label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_TrackingNumber'])"></div>
          {{$t('fbm_TrackingNumber.localization_value.value')}}
        </div>
        <div class="block-table__content">
          <ValueHelper
              :value="FBM.data.trackUserLabel"
          />
        </div>

        <div class="block-table mt-2"
             v-if="FBM.data.UserLabel.data.files.length > 0"
        >
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50 block-table__col--sm-50">
              <div v-for="(item, index) in FBM.data.UserLabel.data.files" :key="index" style="display: flex; align-items: center; margin-bottom: 5px;">
                <img width="25px" src="/img/finance-group/list-no-image.svg" alt="img" style="margin-right: 8px;">
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="block-table mt-4"
             v-if="Object.keys(FBM.data.UserLabel.data.downloadFiles).length > 0"
        >
          <div class="block-table__row product-img-row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <DownloadFileBlock
                  v-for="(item, index) in FBM.data.UserLabel.data.downloadFiles"
                  :key="index+'file'"
                  :file="item"
                  :optionDelete="false"
              />
            </div>
          </div>
        </div>
      </div>


      <div class="order-create__col custom-col d-flex flex-column"
           v-if="FBM.data.shippingPartnerType.signature_price && FBM.data.shippingPartner.can_requaire_signature"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_TheSignFromRecepient','fbm_ServiceFee'])"></div>
        <DefaultCheckbox
                class="block bold"
                :label="$t('fbm_TheSignFromRecepient.localization_value.value')"
                v-model="FBM.data.signFromRecipient"
                :disabled="true"
        />

        <div class="amount-row light pl-4 mt-2"
             v-if="Number(FBM.data.shippingPartnerType.signature_price) > 0"
        >
          {{$t('fbm_ServiceFee.localization_value.value')}}
<!--          <span class="ml-1">${{FBM.data.shippingPartnerType.signature_price}}</span>-->
        </div>
      </div>


    </div>


    <template v-if="show">
      <!--<div class="order-create__section-label section-label mb-3"-->

           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['fbm_shippingCost'])"></div>-->
        <!--{{$t('fbm_shippingCost.localization_value.value')}}-->
      <!--</div>-->
      <!--<div class="order-create__row custom-row">-->
        <!--<div class="order-create__col custom-col">-->
         <!---->
        <!--</div>-->
      <!--</div>-->

      <div class="block-table__row">
        <div class="block-table__col block-table__col--20"
             v-if="!FBM.data.isUserLabel"
        >
          <div class="block-table__label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'fbm_shippingCost',
                        ])"></div>
            {{ $t('fbm_shippingCost.localization_value.value') }}
          </div>
          <div class="block-table__content">
            <ValueHelper
                    :value="FBM.data.shippingCost"
                    :costType="true"
                    :costCurrencyType="'dollar'"
            />
          </div>
        </div>

<!--        <div class="block-table__col block-table__col&#45;&#45;20">-->
<!--          <div class="block-table__label"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate([-->
<!--                          'fbm_PayPalTransactions',-->
<!--                        ])"></div>-->
<!--            {{ $t('fbm_PayPalTransactions.localization_value.value') }}-->
<!--          </div>-->
<!--          <div class="block-table__content">-->
<!--            <router-link class="table-link btn-style"-->
<!--                         v-if="FBM.data.Order.data.transactionId"-->
<!--                         :to="`${$store.getters.GET_PATHS.cusPayments}?page=1&count=25&type=${FBM.data.Order.data.transactionType}&transactionNumber=${FBM.data.Order.data.transactionId}`">-->
<!--              <ValueHelper-->
<!--                      :value="FBM.data.Order.data.transactionId"-->
<!--              />-->
<!--            </router-link>-->
<!--            <span v-else>—</span>-->
<!--          </div>-->
<!--        </div>-->
      </div>

    </template>


    <template v-if="!isWarehouseCambridge && !isWarehouseLodz">
      <template v-if="FBM.data.visibleVatNumber && FBM.data.isVatNumberByCountry">
        <div class="order-create__section-label section-label mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_vatNumberAdmin',])"></div>
          {{$t('common_vatNumberAdmin.localization_value.value')}}
        </div>
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col" v-if="checkIsMarketplaceCompany">
            <div class="mb-3">{{FBM.data.marketplace.name}}</div>

            {{FBM.data.IOSS.number}}
          </div>
          <div class="order-create__col custom-col" v-else-if="FBM.data.vatNumber.length > 0">
            {{FBM.data.vatNumber}}
          </div>

          <div class="order-create__col custom-col"
               v-if="Object.keys(FBM.data.vatNumberFile.data.downloadFiles).length > 0"
          >
            <div class="custom-row product-img-row">
              <div class="custom-col custom-col--16 custom-col--md-33 custom-col--sm-50">
                <DownloadFileBlock
                    v-for="(item, index) in FBM.data.vatNumberFile.data.downloadFiles"
                    :key="index+'file'"
                    :file="item"
                    :optionDelete="false"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>


    <template v-if="!FBM.data.visibleVatNumber && globalCompany && checkIsRequiredVatNumber">
      <template v-if="checkIsRequiredVatNumber">
        <div class="order-create__section-label section-label mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_transactionAmount',])"></div>
          {{$t('fbm_transactionAmount.localization_value.value')}}
        </div>
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col">
            {{FBM.data.transactionAmount}}
          </div>
        </div>
        <div class="order-create__section-label section-label mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_customsClearance',])"></div>
          {{$t('fbm_customsClearance.localization_value.value')}}
        </div>
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col">
            {{FBM.data.customsClearance}}
          </div>
        </div>
      </template>

      <template v-else>
        <div class="order-create__section-label section-label mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_vatNumberAdmin',])"></div>
          {{$t('common_vatNumberAdmin.localization_value.value')}}
        </div>
        <div class="order-create__product-col custom-col"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_vatNumber'])"></div>
          <DefaultCheckbox
              v-model="FBM.data.visibleVatNumber"
              :label="$t('common_vatNumber.localization_value.value')"
              :disabled="true"
          />
        </div>
      </template>

    </template>

    <template v-if="!(globalCompany && isConsolidation) && !FBM.data.isUserLabel">
      <InsuranceBlock
              class="mb-3"
              :FBM="FBM"
              :checkboxState="true"
      />
    </template>

    <template v-if="FBM.data.isInsurance">
      <div class="order-create__section-label section-label mb-3"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_Insurance','fbm_InsuranceToltip'])"></div>
        {{$t('fbm_Insurance.localization_value.value')}}

        <v-popover
            class="site-tooltip"
            offset="5"
            placement="top"
            trigger="hover"
        >
          <TooltipBtn/>
          <template slot="popover">
            <p><b>{{$t('fbm_Insurance.localization_value.value')}}</b></p>
            <p>{{$t('fbm_InsuranceToltip.localization_value.value')}}</p>
          </template>
        </v-popover>
      </div>
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col">
          <RadioDefault
                  :label="'SkladUSA'"
                  :value="true"
                  :helpText="'Insurance for items up to $' + FBM.data.insuranceAmount + ', Insurance for orders $' + FBM.data.insuranceCost"
                  :disabled="true"
          />
        </div>
      </div>
    </template>


    <template v-if="!(isConsolidation && globalCompany) && !isEasyType">
      <div class="order-create__section-label section-label mb-3"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_Packaging'])"></div>
        {{$t('fbm_Packaging.localization_value.value')}}
      </div>
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col">
          <RadioDefault
              v-if="!show"
              :label="_.has(FBM.data.packagingType, 'currentTranslate') ?
          FBM.data.packagingType.currentTranslate.name : 'None'"
              :value="true"
              :disabled="true"
          />

          <div v-if="show" class="fsz14">
            ${{FBM.data.packagingCost}}
          </div>
        </div>
      </div>
    </template>


    <template v-if="isWarehouse && !isEasyType">
      <div class="order-create__section-label section-label mb-3"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_giftPackaging'])"></div>
        {{$t('fbm_giftPackaging.localization_value.value')}}
      </div>
      <div v-if="isAdmin && FBM.data.giftPackagingAdminPrice" class="order-create__row custom-row">
        <div class="order-create__col custom-col">
          <RadioDefault
              v-if="!show"
              :label="_.has(FBM.data.giftPackagingAdminPrice, 'currentTranslate') ?
						FBM.data.giftPackagingAdminPrice.currentTranslate.name : 'None'"
              :value="true"
              :disabled="true"
          />

          <div v-if="show" class="fsz14">
            ${{FBM.data.giftPackagingAdminPrice.price}}
          </div>
        </div>
      </div>
      <div v-else-if="!isAdmin && FBM.data.giftPackagingPrice" class="order-create__row custom-row">
        <div class="order-create__col custom-col">
          <RadioDefault
              v-if="!show"
              :label="_.has(FBM.data.giftPackagingPrice, 'currentTranslate') ?
						FBM.data.giftPackagingPrice.currentTranslate.name : 'None'"
              :value="true"
              :disabled="true"
          />

          <div v-if="show" class="fsz14">
            ${{FBM.data.giftPackagingPrice.price}}
          </div>
        </div>
      </div>
      <div v-else class="order-create__row custom-row">
        <div class="order-create__col custom-col">
          —
        </div>
      </div>
    </template>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50" v-if="isWarehouse">
        <div class="order-create__section-label section-label mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_GiftMessage','fbm_GiftMessageTooltip'])"></div>
          {{$t('fbm_GiftMessage.localization_value.value')}}

          <v-popover
              class="site-tooltip"
              offset="5"
              placement="top"
              trigger="hover"
          >
            <TooltipBtn/>
            <template slot="popover">
              <p><b>{{$t('fbm_GiftMessage.localization_value.value')}}</b></p>
              <p>{{$t('fbm_GiftMessageTooltip.localization_value.value')}}</p>
            </template>
          </v-popover>
        </div>
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col mb-2 word-break">
            {{FBM.data.giftMassage}}
          </div>
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col">
            <RadioDefault
                    :label="_.has(FBM.data.giftPaper, 'name') ?
                FBM.data.giftPaper.name : 'None'"
                    :value="true"
                    :disabled="true"
            />
          </div>
        </div>

      </div>


      <div class="order-create__col custom-col custom-col--20 custom-col--md-50"
           v-if="show && !isEasyType"
      >
        <div class="order-create__section-label section-label mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_OrderPrice'])"></div>
          {{$t('fbm_OrderPrice.localization_value.value')}}
        </div>

        <div>
          <ValueHelper
                  :value="FBM.data.orderPrice"
                  :deep="'cost'"
                  :costType="true"
                  :costCurrencyType="'dollar'"
          />
        </div>
      </div>

      <div class="order-create__col custom-col custom-col--20 custom-col--md-50"
           v-if="show">
        <div class="order-create__section-label section-label mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_shippingCost'])"></div>
          {{$t('fbm_shippingCost.localization_value.value')}}
        </div>

        <div>
          <ValueHelper
                  :value="FBM.data.shippingCost"
                  :costType="true"
                  :costCurrencyType="'dollar'"
          />
        </div>
      </div>

      <div class="order-create__col custom-col custom-col--20 custom-col--md-50"
           v-if="show">
        <div class="order-create__section-label section-label mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_SkladUsaFee'])"></div>
          {{$t('fbm_SkladUsaFee.localization_value.value')}}
        </div>

        <div>
          <ValueHelper
                  :value="FBM.data.skladUsaFee"
                  :costType="true"
                  :costCurrencyType="'dollar'"
          />
        </div>
      </div>
    </div>




  </div>
</template>

<script>


  import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import RadioLabelPicture from "../../../../../../UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";
  import RadioDefault from "../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {FBMMixins} from "../../../../../../../mixins/FBMMixins/FBMMixins";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import InsuranceBlock from "../../chunks/InsuranceBlock/InsuranceBlock";
  import DownloadFileBlock from "../../../../../../coreComponents/DownloadFileBlock/DownloadFileBlock";
  import {DPD_SHIPPING_COMPANY} from "@/staticData/staticVariables";
  import TableHeadButton from "../../../../../../coreComponents/TableComponents/TableHeadButton/TableHeadButton";

  export default {
    name: "StepFour",
    components: {
      DownloadFileBlock,
      InsuranceBlock,
      ValueHelper,
      TooltipBtn,
      RadioLabelPicture,
      RadioDefault,
      DefaultCheckbox,
      TitleHorizontal,
      StatusIcoBtn,
      TableHeadButton,
    },

    mixins: [mixinDetictingMobile, FBMMixins],

    props: {
      FBM: Object,
      dimensions: Object,
      personal: Object,
      deliveryPayPal: Object,
      deliveryEtsy: Object,
      proformProducts: Array,
      products: Array,
      show: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        DPD_SHIPPING_COMPANY: DPD_SHIPPING_COMPANY,
      }
    },

    mounted() {
    },

    methods: {

    },
  }
</script>

<style scoped>

</style>

