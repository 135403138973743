<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getEasyOrdersLoading === true,}">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-if="item.permissionVisible"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate([item.label])"></span>
              <template v-if="item.ico">
                <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
              </template>
              <template v-else>
                {{ $t(item.label + '.localization_value.value')}}
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton @toggleFilter="$emit('toggleFilter')" :count="countFilterParams" :showFilter="showFilter"/>
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block ">
<!--        <div class="btn-left-block-i"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <span class="admin-edit" @click="editTranslate(['common_selectAll'])"></span>-->
<!--          <DefaultCheckbox-->
<!--              :label="$t('common_selectAll.localization_value.value')"-->
<!--              @input="(val) => $emit('selectAll', val)"-->
<!--          />-->
<!--        </div>-->
        <div class="btn-left-block-i">
          <div class="btn-left-block__list">
<!--            <div class="btn-left-block__list__i"-->
<!--                 v-if="_.has(this.currentPermissions, PERMISSIONS.CONSOLIDATION_GENERAL)"-->
<!--            >-->
<!--              <TopTableBoxesBtn-->
<!--                  class="btn-left-block-i&#45;&#45;link"-->
<!--                  :type="'boxes'"-->
<!--                  :title="'common_CreateConsolidation'"-->
<!--                  @click.native="showChooseConsoTypePopup"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="btn-left-block__list__i"-->
<!--                 v-if="navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active || isAdmin">-->
<!--              <TopTableBoxesBtn-->
<!--                  class="btn-left-block-i&#45;&#45;link"-->
<!--                  :type="'pdf'"-->
<!--                  :title="'common_GeneratePdf'"-->
<!--                  @click.native="$emit('generatePdf')"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="btn-left-block__list__i"-->
<!--                 v-if="navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active || isAdmin">-->
<!--              <TopTableBoxesBtn-->
<!--                  class="btn-left-block-i&#45;&#45;link"-->
<!--                  :type="'pdf'"-->
<!--                  :title="'fbm_GenerateBarcodePdf'"-->
<!--                  @click.native="$emit('generateBarcodePdf')"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="btn-left-block__list__i"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['common_ThermalPrint','fbm_ThermalPrintInfo'])"></div>-->
<!--              <TopTableBoxesBtn-->
<!--                  :withTranslate="false"-->
<!--                  class="btn-left-block-i&#45;&#45;link"-->
<!--                  :type="'print'"-->
<!--                  :title="'common_ThermalPrint'"-->
<!--                  @click.native="$emit('thermalPrint')"-->
<!--              />-->
<!--              <v-popover-->
<!--                  class="site-tooltip"-->
<!--                  offset="5"-->
<!--                  placement="top"-->
<!--                  trigger="hover"-->
<!--              >-->
<!--                <TooltipBtn/>-->
<!--                <template slot="popover">-->
<!--                  <p>{{$t('fbm_ThermalPrintInfo.localization_value.value')}}</p>-->
<!--                </template>-->
<!--              </v-popover>-->
<!--            </div>-->
<!--            <div class="btn-left-block__list__i"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['common_MailLabel','fbm_MailLabelInfo'])"></div>-->
<!--              <TopTableBoxesBtn-->
<!--                  :withTranslate="false"-->
<!--                  class="btn-left-block-i&#45;&#45;link"-->
<!--                  :type="'dog'"-->
<!--                  :title="'common_MailLabel'"-->
<!--                  @click.native="$emit('getMailLabel')"-->
<!--              />-->
<!--              <v-popover-->
<!--                  class="site-tooltip"-->
<!--                  offset="5"-->
<!--                  placement="top"-->
<!--                  trigger="hover"-->
<!--              >-->
<!--                <TooltipBtn/>-->
<!--                <template slot="popover">-->
<!--                  <p>{{$t('fbm_MailLabelInfo.localization_value.value')}}</p>-->
<!--                </template>-->
<!--              </v-popover>-->
<!--            </div>-->
<!--            <div class="btn-left-block__list__i"-->
<!--                 v-if="isAdmin">-->
<!--              <TopTableBoxesBtn-->
<!--                  class="btn-left-block-i&#45;&#45;link"-->
<!--                  :type="'pdf'"-->
<!--                  :title="'fbm_royalMailManifest'"-->
<!--                  @click.native="$emit('royalMailManifest')"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="btn-left-block__list__i"-->
<!--                 v-if="isAdmin">-->
<!--              <router-link :to="$store.getters.GET_PATHS.ordersFBMManifests">-->
<!--                <TopTableBoxesBtn-->
<!--                    class="btn-left-block-i&#45;&#45;link"-->
<!--                    :type="'pdf'"-->
<!--                    :title="'fbm_royalMailManifestList'"-->
<!--                />-->
<!--              </router-link>-->
<!--            </div>-->
<!--            <div class="btn-left-block__list__i">-->
<!--              <TopTableBoxesBtn-->
<!--                  v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && isUserPrepayment"-->
<!--                  class="btn-left-block-i&#45;&#45;link"-->
<!--                  :type="'dollar'"-->
<!--                  :title="'fbm_pay'"-->
<!--                  @click.native="$emit('payFBM')"-->
<!--              />-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <div class="btn-right-block">
        <div v-if="navTabs.all.active"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
        >
          <div class="admin-edit" @click="editTranslate(['common_AddNew'])"></div>
          <MainButton class="btn-fit-content btn-right-block-i"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
                      @click.native="isModalChooseEasyOrderCreatePopup = true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </div>

        <router-link
            v-else
            :to="$store.getters.GET_PATHS.easyOrderCreation + getCreateLink"
        >
          <MainButton class="btn-fit-content"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>

      </div>
    </div>


    <ChooseEasyOrderCreatePopup
        v-if="isModalChooseEasyOrderCreatePopup"
        @close="isModalChooseEasyOrderCreatePopup = false"
    />


  </div>
</template>

<script>
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
// import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import ToggleFilterButton from "@/components/UI/buttons/ToggleFilterButton/ToggleFilterButton";
import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";
import ChooseEasyOrderCreatePopup
  from "@/components/coreComponents/Popups/ChooseEasyOrderCreatePopup/ChooseEasyOrderCreatePopup";
import {EASY_ORDER_TYPES_ARRAY} from "@/staticData/staticVariables";
import {easyOrdersMixin} from "@/mixins/easyOrdersMixins/easyOrdersMixin";

export default {
  name: "EasyOrdersTableHead",
  components: {
    ChooseEasyOrderCreatePopup,
    ToggleFilterButton,
    // DefaultCheckbox,
    MainButton,
    PlusIconSVG,
  },

  props: {
    showFilter: Boolean,
    countFilterParams: Number,
    navTabs: Object,
  },

  mixins: [
    routeFilter,
    easyOrdersMixin,
  ],

  data() {
    return {
      isModalChooseEasyOrderCreatePopup: false,
    }
  },

  computed: {
    getCreateLink() {
      let active = this._.find(this.navTabs, {active: true})
      let type = this._.find(EASY_ORDER_TYPES_ARRAY, {name: active.name})

      return this.getEasyOrderType(type.value)
    },
  },

  watch: {

  },

  mounted() {

  },

  methods: {

  },

}
</script>

<style scoped>

</style>