<template>
  <div class="fragment">

    <template v-if="$store.getters.getEasyOrders.length > 0 && !$store.getters.getEasyOrdersLoading">
      <div class="site-table-wrap order-fbm-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'fbm_id',
            'fbm_Created',
            'fbm_TrackingNumber',
            'common_manage',
            'common_edit',
            'common_delete',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
<!--            <th></th>-->
            <th>{{$t('fbm_id.localization_value.value')}}</th>
            <th width="10%">{{$t('fbm_Created.localization_value.value')}}</th>
<!--            <th v-if="isAdmin">{{$t('common_user.localization_value.value')}}</th>-->
<!--            <th>{{$t('fbm_Recepient.localization_value.value')}}</th>-->
<!--            <th>{{$t('fbm_PayPalTransactions.localization_value.value')}}</th>-->
            <!--<th>{{$t('fbm_Lots.localization_value.value')}}</th>-->
<!--            <th>{{$t('fbm_Ship.localization_value.value')}}</th>-->
            <th width="100%">{{$t('fbm_TrackingNumber.localization_value.value')}}</th>
<!--            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active">{{$t('fbm_DHLTracknumber.localization_value.value')}}</th>-->
<!--            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name].active">{{$t('fbm_ConsoID.localization_value.value')}}</th>-->
<!--            <th>{{$t('fbm_WasSentAt.localization_value.value')}}</th>-->
<!--            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && (isUserPrepayment || isAdmin)">{{$t('fbm_PrepaidTotal.localization_value.value')}}</th>-->
<!--            <th v-if="!navTabs[FBM_ORDER_TYPES.FBM_ORDER_LABEL.name].active && (isUserPrepayment || isAdmin)">{{$t('fbm_Paid.localization_value.value')}}</th>-->
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr
              v-for="(item, index) in $store.getters.getEasyOrders"
              :key="index"
              v-bind:class="{'site-table__draft-row': item.in_draft === 1}"
          >
<!--            <td>-->
<!--              <DefaultCheckbox-->
<!--                  class="empty-label"-->
<!--                  :selectedNow="selectedNow"-->
<!--                  :dataValue="item.id"-->
<!--              />-->
<!--            </td>-->
            <td>
              <router-link
                  :to="getShowLink(item)"
                  class="table-link btn-style">
                #{{ item.order_id }}
              </router-link>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td>
              <ValueHelper
                  v-if="item.system_tracking && item.order_type === 'econom'"
                  :value="item.system_tracking"
              />
              <ValueHelper
                  v-else
                  :value="item.tracking_number"
              />
            </td>
<!--            <td v-if="isAdmin">-->
<!--              <TableUserColumn-->
<!--                  v-if="isAdmin"-->
<!--                  :item="item"-->
<!--              />-->
<!--            </td>-->
            <td>
              <div class="d-flex">
                <LinkButton
                    class="mr-3 white-space-nowrap"
                    v-if="item.status !== 'completed'"
                    :value="item.in_draft ? $t('common_EditDraft.localization_value.value') : $t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="getEditLink(item)"
                />

                <LinkButton
                    v-if="item.status !== 'completed'"
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('deleteOrder', item)"
                />
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getEasyOrders"
                 :class="{'draft-row': item.in_draft === 1}"
                 :key="index"
            >
              <EasyOrdersTableSectionTableMobile
                  :item="item"
                  :selectedNow="selectedNow"
                  :navTabs="navTabs"
                  :getEditLink="getEditLink(item)"
                  :getShowLink="getShowLink(item)"
                  @deleteOrder="(val) => $emit('deleteOrder', val)"
              />
            </div>
          </div>
        </div>
      </div>


      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getEasyOrdersCommonList.next_page_url !== null && $store.getters.getEasyOrders.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextEasyOrdersPage}"
              :count="$store.getters.getEasyOrdersCommonList.total - $store.getters.getEasyOrdersForPage * $store.getters.getEasyOrdersCommonList.current_page < $store.getters.getEasyOrdersForPage ?
                  $store.getters.getEasyOrdersCommonList.total - $store.getters.getEasyOrdersForPage * $store.getters.getEasyOrdersCommonList.current_page:
                  $store.getters.getEasyOrdersForPage"
          />
<!--          <ExportBtn-->
<!--              class="table-bottom-btn__right"-->
<!--              :customType="_.has($store.getters.getEasyOrdersCommonList, 'addition_export') ? $store.getters.getEasyOrdersCommonList.addition_export : null"-->
<!--              @downloadFiles="type => $emit('downloadFiles', type)"-->
<!--          />-->
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getEasyOrdersLoading === false && $store.getters.getEasyOrders.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

<!--    <StatusOrderDetailsPopup-->
<!--        @close="closeStatusOrderDetailsPopup"-->
<!--        v-if="isModalStatusOrderDetailsPopup"-->
<!--    />-->


  </div>
</template>

<script>
// import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import {mixinDetictingMobile} from "@/mixins/mobileFunctions";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {easyOrdersMixin} from "@/mixins/easyOrdersMixins/easyOrdersMixin";
import EasyOrdersTableSectionTableMobile
  from "@/components/modules/EasyOrdersModule/components/EasyOrdersTable/EasyOrdersTableSection/EasyOrdersTableSectionTable/EasyOrdersTableSectionTableMobile/EasyOrdersTableSectionTableMobile";
export default {
  name: "EasyOrdersTableSectionTable",

  components: {
    EasyOrdersTableSectionTableMobile,
    ValueHelper,
    LinkButton,
    NoResult,
    ShowMore
  },

  mixins: [
      mixinDetictingMobile,
      easyOrdersMixin,
  ],

  props: {
    selectedNow: Boolean,
    countFilterParams: Number,
    navTabs: Object,
  },

  data() {
    return {

    }
  },

  methods: {
    getEditLink(item) {
      let type = this.getEasyOrderType(item.order_type)

      return this.$store.getters.GET_PATHS.easyOrderEdit + `/${type}/` + item.order_id
    },

    getShowLink(item) {

      let type = this.getEasyOrderType(item.order_type)

      return this.$store.getters.GET_PATHS.easyOrderShow + `/${type}/` + item.order_id
    },
  },


}
</script>

<style scoped>

</style>